<template>
  <div>
    <div class="bg-image" style="background-image: url('https://source.unsplash.com/1920x1280/?forest')">
      <div class="bg-black-25">
        <div class="content content-full">
          <div class="py-5 text-center">
            <h1 class="font-w700 my-2 text-white">{{ entity.name }}</h1>
            <h2 class="h4 font-w700 text-white-75">{{ entity.companyNumber }}</h2>
            <router-link class="btn btn-hero-primary" :to="{ name: 'entity-edit', params: { id: entity._id } }">
              <i class="fa fa-fw fa-pencil mr-1"></i> Edit Entity
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EntityView',
  data() {
    return {
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      entity: 'entity/entity'
    })
  },
  created() {
    this.getEntity({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getEntity: 'entity/get'
    })
  }
};
</script>
